import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import calendar from 'assets/icons/calendar.svg';
import calendarCheck from 'assets/icons/calendar-check-01.svg';
import locationIcon from 'assets/icons/location.svg';
import racquet from 'assets/icons/racquet.svg';
import phoneIcon from 'assets/icons/phone.svg';
import Button from 'components/Button';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

export default function TournamentPartnerCard({
  avatar,
  name,
  ntrp,
  location,
  tournament,
  userId,
  mailTo,
  hideButtons,
  playUntil,
  phoneNumber,
  matchData,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    match_city,
    match_court,
    match_date,
    match_time,
    match_court_number,
    city,
    isContinued,
  } = matchData;
  const matchDate = match_date && moment(match_date).format('YYYY-MM-DD');
  const matchTime = match_time ? ` ${match_time}` : '';
  const haveMatchInfo = match_city && match_court && matchDate;
  const matchInfoString = haveMatchInfo
    ? `${matchDate}${matchTime} (${match_court} / ${match_court_number})`
    : t('notScheduled');

  const finalDayVilnius =
    playUntil === process.env.REACT_APP_VILNIUS_FINAL_DATE;
  const finalDayKaunas = playUntil === process.env.REACT_APP_KAUNAS_FINAL_DATE;
  const playUntilText =
    finalDayVilnius || finalDayKaunas ? t('finalDay') : t('playUntil');
  const finalsText =
    isContinued === 0 && (finalDayVilnius || finalDayKaunas)
      ? `${t('individually')} ${process.env.REACT_APP_MINI_FINAL_DATE_TEXT}`
      : finalDayVilnius
      ? `SEB Arena (${process.env.REACT_APP_VILNIUS_FINAL_DATE_TEXT})`
      : finalDayKaunas
      ? `Tennis Space (${process.env.REACT_APP_KAUNAS_FINAL_DATE_TEXT})`
      : `${playUntil} (liko ${Math.max(
          0,
          moment(playUntil).endOf('day').diff(moment(), 'days') + 1,
        )} d.)`;

  return (
    <Card
      sx={{
        maxWidth: { xs: '100%', md: '416px' },
        maxHeight: { xs: 'auto', md: '570px' },
        borderRadius: '16px',
        padding: { xs: '24px', md: '32px' },
      }}
    >
      <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} height={60} width={60}>
              <Box
                component={'img'}
                src={
                  avatar
                    ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
                    : avatarGrey
                }
                alt="avatar"
                height={60}
                width={60}
                borderRadius={'50%'}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => navigate(`/info/${userId}`)}
            >
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'16px'}
                sx={{
                  whiteSpace: 'wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: { xs: '130px', sm: 'auto' },
                }}
              >
                {name ? name : 'Laukiama žaidėjo..'}
              </Typography>
            </Box>
          </Box>
          <Box
            height={'28px'}
            backgroundColor={theme.palette.darkGrey[100]}
            borderRadius={'40px'}
            padding={'4px 20px'}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography
              variant={'body5'}
              fontWeight={600}
              color={theme.palette.orange[700]}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {ntrp} NTRP
            </Typography>
          </Box>
        </Box>
        <Box marginTop={'20px'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'flex-start'}>
            <Box
              component={'img'}
              src={racquet}
              height={1}
              borderRadius={'50%'}
              alt="racquet"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('tournament')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {tournament}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
            <Box
              component={'img'}
              src={locationIcon}
              height={1}
              borderRadius={'50%'}
              alt="location"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('location')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
            >
              {location}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
            <Box
              component={'img'}
              src={calendar}
              height={1}
              borderRadius={'50%'}
              alt="calendar"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {playUntilText}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
            >
              {finalsText}
            </Typography>
          </Box>
          {(!hideButtons || hideButtons === 'undefined') && (
            <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
              <Box
                component={'img'}
                src={phoneIcon}
                height={1}
                borderRadius={'50%'}
                alt="phone"
              />
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[700]}
              >
                {t('phoneNumber')}:
              </Typography>
              <a
                href={`tel:${phoneNumber}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant={'body3'}
                  marginLeft={'4px'}
                  color={theme.palette.darkGrey[600]}
                >
                  {phoneNumber}
                </Typography>
              </a>
            </Box>
          )}
          {(!hideButtons || hideButtons === 'undefined') && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              marginTop={'8px'}
              justifyContent={'flex-start'}
            >
              {!finalDayVilnius && !finalDayKaunas && (
                <Box display={'flex'} alignItems={'center'}>
                  <Box
                    component={'img'}
                    src={calendarCheck}
                    height={'18px'}
                    width={'18px'}
                    borderRadius={'50%'}
                    alt="planned match info"
                  />
                  <Typography
                    variant={'body3'}
                    fontWeight={600}
                    marginLeft={'8px'}
                    color={theme.palette.darkGrey[700]}
                  >
                    {t('plannedMatch')}:
                  </Typography>
                  {matchInfoString !== t('notScheduled') ? null : (
                    <Typography
                      variant={'body3'}
                      marginLeft={'4px'}
                      color={theme.palette.darkGrey[600]}
                    >
                      {t('notScheduled')}
                    </Typography>
                  )}
                </Box>
              )}
              {matchInfoString === t('notScheduled') ? null : (
                <Box>
                  <Typography
                    variant={'body4'}
                    color={theme.palette.darkGrey[600]}
                    marginTop={'8px'}
                  >
                    {matchInfoString}
                  </Typography>
                  <AddToCalendarButton
                    name={`Teniso mačas prieš: ${name}`}
                    description={`Teniso Partneris turnyro mačas: ${match_court} ${match_court_number} aikštelėje, ${moment(
                      match_date,
                    ).format('YYYY-MM-DD')} ${match_time} val. ${city} mieste.`}
                    label={t('addToCalendar')}
                    options={['Apple', 'Google']}
                    location={`${match_city}, ${match_court} ${match_court_number} aikštelė`}
                    startDate={moment(match_date).format('YYYY-MM-DD')}
                    endDate={moment(match_date).format('YYYY-MM-DD')}
                    startTime={moment(match_time, 'HH:mm').format('HH:mm')}
                    endTime={moment(match_time, 'HH:mm')
                      .add(1, 'hours')
                      .add(30, 'minutes')
                      .format('HH:mm')}
                    timeZone="Europe/Vilnius"
                    size="0"
                    hideCheckmark
                  ></AddToCalendarButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </CardContent>
      {(!hideButtons || hideButtons === 'undefined') && userId !== 0 && (
        <Box marginTop="32px">
          {!finalDayVilnius && !finalDayKaunas && (
            <Button
              width={'100%'}
              buttontype="orange"
              onClick={() =>
                navigate('/siusti-zinute', {
                  state: {
                    userId,
                    name,
                    avatar,
                    ntrp,
                    location,
                    mailTo,
                    mailSubject: `${tournament} (${location})`,
                    isTournament: true,
                  },
                })
              }
            >
              {t('sendMessage')}
            </Button>
          )}
          <Button
            marginTop={'12px'}
            width={'100%'}
            buttontype="white"
            bordered="true"
            onClick={() =>
              navigate('/ivesti-rezultata', {
                state: { matchData, isTournament: true },
              })
            }
          >
            {t('enterResult')}
          </Button>
          {!finalDayVilnius && !finalDayKaunas && (
            <Button
              marginTop={'12px'}
              width={'100%'}
              buttontype="white"
              bordered="true"
              onClick={() =>
                navigate('/planuoti-susitikima', {
                  state: { matchData, isTournament: true },
                })
              }
            >
              {t('scheduleMatch')}
            </Button>
          )}
        </Box>
      )}
    </Card>
  );
}
