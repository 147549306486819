import React from 'react';
import { Seed, SeedTeam, SeedItem, SeedTime } from 'react-brackets';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const isMd = window.innerWidth >= 960;

const StyledSeed = styled(Seed)({
  height: 'auto !important',
  padding: '24px !important',
  overflow: isMd ? 'visible !important' : 'hidden !important',
  '&::before': {
    borderColor: '#D0D5DD !important',
    borderWidth: '2px !important',
  },
  '&::after': {
    borderColor: '#D0D5DD !important',
    borderWidth: '2px !important',
  },
});

const StyledSeedTime = styled(SeedTime)({
  width: '336px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > span': {
    backgroundColor: '#fff',
    width: '100%',
    borderBottomRightRadius: '16px !important',
    borderBottomLeftRadius: '16px !important',
    borderTop: '1px solid #D0D5DD',
    paddingTop: '4px',
    paddingBottom: '4px',
    textAlign: 'center',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08) !important',
  },
});

const StyledTeam = styled(SeedTeam)({
  backgroundColor: '#fff !important',
  '&:first-of-type': {
    padding: '16px !important',
    boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08) !important',
    borderTopLeftRadius: '16px !important',
    borderTopRightRadius: '16px !important',
    '& > div > div': {
      width: '304px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
      color: '#344054',
      fontWeight: 600,
      fontSize: '14px',
      boxShadow: 'none',
      '&:first-of-type': {
        marginBottom: '12px',
      },
    },
  },
});

const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
  const theme = useTheme();
  const finalDay = seed.date === process.env.REACT_APP_VILNIUS_FINAL_DATE;
  const finalsText1 = `Finalinė diena (Vilnius ${process.env.REACT_APP_VILNIUS_FINAL_DATE_TEXT} / Kaunas ${process.env.REACT_APP_KAUNAS_FINAL_DATE_TEXT})`;
  const finalsText2 = `Savarankiškai iki ${process.env.REACT_APP_MINI_FINAL_DATE_TEXT}`;
  const finalsText = seed.isContinued === 1 ? finalsText1 : finalsText2;

  return (
    <StyledSeed mobileBreakpoint={0}>
      <StyledTeam>
        <div>
          <SeedItem>
            {seed.teams[0]?.name || (
              <Box sx={{ marginLeft: '12px' }}>
                <Typography
                  variant={'body4'}
                  fontWeight={600}
                  color={theme.palette.darkGrey[700]}
                >
                  Laukiama žaidėjo..
                </Typography>
              </Box>
            )}
          </SeedItem>
          <SeedItem>
            {seed.teams[1]?.name || (
              <Box sx={{ marginLeft: '12px' }}>
                <Typography
                  variant={'body4'}
                  fontWeight={600}
                  color={theme.palette.darkGrey[700]}
                >
                  Laukiama žaidėjo..
                </Typography>
              </Box>
            )}
          </SeedItem>
        </div>
      </StyledTeam>
      {seed.isActive ? (
        <StyledSeedTime>
          <Typography
            variant={'body5'}
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {finalDay
              ? finalsText
              : `Sužaisti iki: ${seed.date} (liko
          ${Math.max(
            0,
            moment(seed.date).endOf('day').diff(moment(), 'days') + 1,
          )}
          d.)`}
          </Typography>
        </StyledSeedTime>
      ) : null}
    </StyledSeed>
  );
};

export default CustomSeed;
