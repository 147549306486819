import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import phoneIcon from 'assets/icons/phone.svg';
import emailIcon from 'assets/icons/mail.svg';
import messengerIcon from 'assets/icons/messenger.svg';
import locationIcon from 'assets/icons/location.svg';
import calendar from 'assets/icons/calendar.svg';
import helpCircle from 'assets/icons/help-circle.svg';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import pencil from 'assets/icons/pencil-line.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { get, getTokenFromStorage } from 'api';
import CryptoJS from 'crypto-js';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.base.white,
    color: theme.palette.darkGrey[700],
    boxShadow:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    fontSize: 12,
    fontFamily: 'Inter, sans-serif',
    padding: '8px 12px',
    borderRadius: '8px',
  },
}));

const UserInfoCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { id } = useParams();
  const token = getTokenFromStorage();
  const [userInfo, setUserInfo] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const infoText = t('ntrpExplanation');

  const getUserInfo = async () => {
    try {
      const response = await get(`/accounts/myprofile`, token);
      const data = await response.json();
      setUserInfo(data[0]);
      setLoaded(true);
    } catch (err) {
      console.error(err.message);
    }
  };
  const getProfileInfo = async () => {
    try {
      const response = await get(`/accounts/user-profile/${id}`, token);
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData.length === 0) {
        navigate('/info');
        return;
      }
      setUserInfo(decryptedData[0]);
      setLoaded(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileInfo();
    } else {
      getUserInfo();
    }
  }, [id]);

  useEffect(() => {
    if (id && token && !loaded) {
      getProfileInfo();
    }
  }, [id, loaded]);

  if (!loaded || !userInfo) {
    return null;
  }

  const {
    first_name,
    last_name,
    email,
    phone_number,
    messenger,
    city,
    ntrp,
    showEmail,
    showPhone,
    image,
    description,
  } = userInfo;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.darkGrey[100],
        borderRadius: '20px',
        padding: { xs: '24px', sm: '32px' },
        width: { xs: '100%', sm: '100%', md: '1088px' },
        maxHeight: { xs: '100%', sm: '228px' },
        height: '100%',
        gap: '24px',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: 'auto', md: '1088px' }}
          maxHeight={{ xs: '100%', sm: '104px' }}
          height={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            component={'img'}
            src={
              image
                ? `https://backend.tenisopartneris.lt/public/images/${image}`
                : avatarWhite
            }
            alt="avatar"
            height={{ xs: 78, sm: 104 }}
            width={{ xs: 78, sm: 104 }}
            borderRadius={'50%'}
            sx={{
              objectFit: 'cover',
            }}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            marginLeft={{ sm: '16px' }}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant={isMd ? 'h6' : 'body1'}
              sx={{
                fontWeight: 600,
                color: theme.palette.darkGrey[700],
                marginBottom: { xs: '8px', sm: '8px', md: '14px' },
              }}
            >
              {first_name} {last_name}
            </Typography>
            <Box
              display={'flex'}
              alignItems="center"
              marginBottom={{ xs: '8px', sm: '8px', md: '12px' }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  component={'img'}
                  src={locationIcon}
                  height={1}
                  borderRadius={'50%'}
                  alt="location"
                />
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  marginLeft={'8px'}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('city')}:
                </Typography>
              </Box>
              <Typography
                variant={'body3'}
                marginLeft="8px"
                fontWeight={500}
                color={theme.palette.darkGrey[500]}
              >
                {city}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  component={'img'}
                  src={calendar}
                  height={1}
                  borderRadius={'50%'}
                  alt="calendar"
                />
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  marginLeft={'8px'}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('bio')}:
                </Typography>
              </Box>
              <Typography
                variant={'body3'}
                marginLeft={{ sm: '8px' }}
                marginTop={{ xs: '8px', sm: '0px' }}
                fontWeight={500}
                color={theme.palette.darkGrey[500]}
              >
                {description ? description : '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            position: { xs: 'absolute', sm: 'relative' },
            right: { xs: '32px', sm: '0px' },
          }}
        >
          <Box
            height={'28px'}
            backgroundColor={theme.palette.base.white}
            borderRadius={'40px'}
            padding={'4px 20px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'135px'}
          >
            <Typography
              variant={isMd ? 'body5' : 'body4'}
              fontWeight={600}
              color={theme.palette.base.black}
            >
              {ntrp} NTRP
            </Typography>
          </Box>
          <LightTooltip
            title={infoText}
            placement={'top-start'}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
          >
            <Box
              component={'img'}
              src={helpCircle}
              marginLeft={'8px'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowTooltip(!showTooltip)}
              alt="help-circle"
            />
          </LightTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: { xs: '20px', sm: '24px' },
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              padding: '8px 16px',
              backgroundColor: theme.palette.base.white,
              borderRadius: '8px',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.08)',
              maxWidth: { xs: '100%', md: '215px' },
              width: 'auto',
            }}
          >
            <Box component={'img'} src={phoneIcon} alt="phone" />
            <Typography
              variant="body4"
              fontWeight={600}
              color={theme.palette.darkGrey[700]}
              marginLeft={'8px'}
            >
              {showPhone ? (
                <a
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.darkGrey[700],
                  }}
                  href={`tel:${phone_number}`}
                >
                  {phone_number}
                </a>
              ) : (
                t('notAvailable')
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              padding: '8px 16px',
              backgroundColor: theme.palette.base.white,
              borderRadius: '8px',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.08)',
              maxWidth: { xs: '100%', sm: '315px' },
              width: 'auto',
              marginLeft: { sm: '20px' },
              marginTop: { xs: '12px', sm: '0px' },
            }}
          >
            <Box component={'img'} src={emailIcon} alt="email" />
            <Typography
              variant="body4"
              fontWeight={600}
              color={theme.palette.darkGrey[700]}
              marginLeft={'8px'}
            >
              {showEmail ? (
                <a
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.darkGrey[700],
                  }}
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              ) : (
                t('notAvailable')
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              padding: '8px 16px',
              backgroundColor: theme.palette.base.white,
              borderRadius: '8px',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.08)',
              maxWidth: { xs: '100%', sm: '315px' },
              width: 'auto',
              marginLeft: { sm: '20px' },
              marginTop: { xs: '12px', sm: '0px' },
            }}
          >
            <Box component={'img'} src={messengerIcon} alt="messenger" />
            <Typography
              variant="body4"
              fontWeight={600}
              color={theme.palette.darkGrey[700]}
              marginLeft={'8px'}
            >
              {messenger && messenger !== 'https://m.me/' ? (
                <a
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.darkGrey[700],
                  }}
                  href={messenger}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messenger}
                </a>
              ) : (
                t('notAvailable')
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            marginLeft: { xs: '0px', sm: '20px' },
            cursor: 'pointer',
            '&:hover': {
              '& img': {
                transform: 'scale(1.1)',
              },
            },
          }}
          onClick={() => navigate('/redaguoti-profili')}
        >
          <Box component={'img'} src={pencil} alt="edit" />
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoCard;
